.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 44px;
  border-radius: 8px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 14px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  min-height: 44px;
  border-radius: 8px;
}
.ant-select-multiple .ant-select-selection-placeholder {
  padding-top: 14px;
}
.selectStyle
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 6px !important;
}
.ant-select-selection-overflow {
  padding-top: 14px;
}
.ant-select-multiple .ant-select-selection-item {
  height: 20px;
  display: flex;
  border-radius: 5px;
  align-items: center;
}
