.print-only {
  display: block;
}

.print-only-name {
  display: none;
}

/* Show the element only during printing */
@media print {
  .print-only {
    display: none;
  }
  .print-only-name {
    display: block;
  }
}
