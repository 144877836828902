.main {
  margin: 0 auto;
  display: grid;
  place-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.container {
  background-color: rgb(255, 255, 255);
  width: 640px;
  display: grid;
  place-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.consesntContainer {
  background-color: rgb(255, 255, 255);
  width: 640px;
  height: 980px;
  display: grid;
  place-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.form-container {
  border: 1px solid black;
  width: 600px;
  height: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
}

.paragraph {
  font-size: 0.8em;
  font-weight: 400;
}

.patientData {
  font-weight: 500;
  border-bottom: 1px solid black;
  padding: 0px 60px;
}

.signature-container {
  margin-top: 20px;
  position: relative;
  width: 100%;
  padding-bottom: 150px;
  border: 1px solid lightgray;
  border-radius: 10px;
}

.signature-container .sigCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.signature-container-text {
  margin-top: 20px;
  position: relative;
  width: 100%;
  padding-bottom: 50px;
  border: 1px solid lightgray;
  border-radius: 10px;
}

.consentFormHeader {
  margin-top: 40px;
}

.formField {
  margin-left: 103px;
}

.mobile-consent-modal {
  width: 90vw;
  overflow-y: auto;
}

@media only screen and (max-width: 768px) {
  .logo-left {
    width: 100%;
  }

  .consentFormHeader {
    margin-top: 20px;
  }

  .form-container {
    border: 1px solid black;
    width: 77vw;
    height: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
  }

  .patientData {
    font-weight: 500;
    border-bottom: 1px solid black;
    padding: 0px 10px;
    margin-left: 0px;
  }

  .consesntContainer {
    margin-top: 0;
    padding-top: 0;
    width: 100%;
  }

  .header {
    flex-wrap: wrap;
  }

  .formField {
    margin-left: 0px;
  }
}
