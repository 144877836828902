.ant-picker-panel .ant-picker-footer {
  display: none;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border: none;
}
.disable-arrow1 .ant-picker-header-super-prev-btn,
.disable-arrow2 .ant-picker-header-prev-btn,
.disable-arrow3 .ant-picker-header-next-btn,
.disable-arrow4 .ant-picker-header-super-next-btn {
  display: none;
}
.ant-picker-date-panel .ant-picker-content th {
  background-color: #f5f9fc;
  color: #979dab;
  font-size: 12px;
}
.ant-picker-header {
  border: 1px solid #e2edf3;
  border-radius: 10px;
  margin: 10px;
}
.ant-picker-cell .ant-picker-cell-inner {
  font-size: 12px;
}
.ant-picker-panel-container {
  margin-top: 15px;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 8px;
  padding: 5px;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  border-radius: 8px;
  box-shadow: 0 3px 8px 0 rgba(6, 157, 222, 0.5);
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  border-radius: 8px;
  background-color: #ebf4f8;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  padding: 0;
  display: grid;
  place-items: center;
  width: 50px;
}
.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  height: 100px;
}
.ant-picker-time-panel-column {
  height: 70px;
  display: grid;
  place-items: center;
  width: 65px;
}
.ant-picker-time-panel-column::after {
  height: 12px;
}
.ant-picker-time-panel-column {
  border: 1px solid #e2edf3;
  border-radius: 10px;
  margin: 5px;
}
.ant-picker-panel-container {
  border-radius: 10px;
  box-shadow: none;
}
