.ant-table {
  background: tranparent;
}
.ant-table-cell > td {
  background-color: white;
}
.ant-table-container table > thead > tr:first-child > th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 15px;
}
.ant-table-container table > thead > tr:first-child > th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ant-table-small .ant-table-thead > tr > th {
  background: #f5f9fc;
}
.ant-table-middle .ant-table-thead > tr > th {
  background: #f5f9fc;
}
.ant-table-thead > tr > th {
  background: #f5f9fc;
  padding: 8px;
  font-size: 10px;
  height: 36px;
  font-weight: bold;
  color: #858b97;
  letter-spacing: 0;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}
.ant-table-tbody > tr > td {
  padding: 5px;
  font-size: 12px;
  border-bottom: 2px solid #edf4f8;
}

td.ant-table-column-sort {
  background: #fff;
}
