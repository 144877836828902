.mainContainer {
  display: flex;
  width: 100%;
}

.form_container {
  border: 1px solid black;
  width: 600px;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
}

.leftContainer {
  border: 0.5px solid;
  width: 60%;
  margin: 30px;
  padding: 15px;
}

.rightContainer {
  width: 40%;
  margin-top: 1%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.paragraph {
  font-size: 0.8em;
  font-weight: 400;
}

.patientData {
  font-weight: 500;
  border-bottom: 1px solid black;
  padding: 0px 60px;
}

.signature-container {
  margin-top: 20px;
  position: relative;
  width: 100%;
  padding-bottom: 150px;
  border: 1px solid lightgray;
  border-radius: 10px;
}

.signature-container .sigCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.signature-container-text {
  margin-top: 20px;
  position: relative;
  width: 100%;
  padding-bottom: 50px;
  border: 1px solid lightgray;
  border-radius: 10px;
}

.leftAlign {
  text-align: left;
}
