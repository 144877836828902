body {
  margin: 0;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

@font-face {
  font-family: "Century Gothic";
  src: url(../public/GOTHIC.TTF);
}

@font-face {
  font-family: "Century Gothic Bold";
  src: url(../public/GOTHICB.TTF);
  font-weight: bold;
}

@font-face {
  font-family: "Century Gothic Bold Italic";
  src: url(../public/GOTHICBI.TTF);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Century Gothic Italic";
  src: url(../public/GOTHICI.TTF);
  font-style: italic;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 4px;
}

::-webkit-scrollbar-button {
  background-color: transparent;
  display: none;
}

::-webkit-scrollbar-thumb {
  height: auto;
  background-color: #848da3;
  border-radius: 3px;
}

::-webkit-scrollbar-corner {
  background-color: #0a4976;
  background-color: #f3f6fc;
}

::-webkit-resizer {
  background-color: #848da3;
}
